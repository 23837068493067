.btn.question {
  background-color: rgba(0,0,0,0);
  border: none;
  color: #2c3e50;
}
.btn.question:hover {
  color: rgba(44,62,80,0.471);
}
.btn.question:focus {
  border: none;
}
/*# sourceMappingURL=src/components/pages/currencies/CurrenciesPage.css.map */