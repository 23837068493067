






















































































.btn.question
  background-color rgba(0, 0, 0, 0)
  border none 
  color #2c3e50

.btn.question:hover
  color #2c3e5078

.btn.question:focus
  border none 

